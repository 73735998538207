<template>
  <v-main>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <cookie-bar></cookie-bar>
  </v-main>
</template>

<script>
  import CookieBar from '../../components/base/CookieBar.vue';
  export default {
  components: { CookieBar },
    name: 'BaseView',
  }
</script>
